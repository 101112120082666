import { React, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAbandonedCartRequest } from '../../redux_store/actions/abandonedCartActions';

const AbandonedCart = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const { abandonedCart, loading, error } = useSelector((state) => state.abandonedCart);

  useEffect(() => {
    dispatch(fetchAbandonedCartRequest());
  }, [dispatch]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

  console.log("anees -- ", abandonedCart);

  // Ensure abandonedCart is an array and check its structure
  if (!Array.isArray(abandonedCart) || abandonedCart.length === 0) {
    return <div>No data available</div>;
  }

  // Dynamically generate columns based on the first object in the array
  const columns = Object.keys(abandonedCart).map((key) => {
    if (key === 'email') {
      return {
        name: 'Email',
        selector: (row) => (row.email ? row.email : ''),
        sortable: true,
      };
    }
    if (key === 'fullName') {
      return {
        name: 'Full Name',
        selector: (row) => (row.fullName ? row.fullName : ''),
        sortable: true,
      };
    }
    if (key === 'cart') {
      return {
        name: 'Cart',
        selector: (row) => (row.cart ? row.cart : ''),
        sortable: true,
      };
    }

    // If the field is an object, handle it accordingly (e.g., convert to string)
    if (typeof abandonedCart[key] === 'object') {
      return {
        name: key.charAt(0).toUpperCase() + key.slice(1),
        selector: (row) => JSON.stringify(row[key]),
        sortable: true,
      };
    }

    // Default column generation for other fields
    return {
      name: key.charAt.toUpperCase() + key.slice(1),
      selector: (row) => row[key],
      sortable: true,
    };
  });

  // Remove unwanted columns (e.g., Id, CreatedAt, LastModified)
  const columnsToRemove = ['Id', 'CreatedAt', 'LastModified'];
  const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

  // Adding actions column
  const actionsColumn = {
    name: 'Actions',
    cell: (row) => (
      <>
        {/* Action buttons (e.g., view or edit) can be added here */}
      </>
    ),
    sortable: false,
  };

  // Combine the actions column with the filtered columns
  const modifiedColumns = [actionsColumn, ...filteredColumns];

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3>Abandoned Cart</h3>
        </div>
        <div className="card-body">
          <DataTable
            pagination
            columns={modifiedColumns}
            data={abandonedCart}
            selectableRows
            selectableRowsHighlight
            onSelectedRowsChange={handleRowSelected}
            selectedRows={selectedRows}
            progressPending={loading}
            progressComponent={<ColorRing />}
            responsive={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AbandonedCart;
